/* eslint-disable prettier/prettier */
import { stringify } from 'query-string';

import { IExtendedFetchResult, RequestMethods, isKnownUserUuid } from 'networking/fetchConfig';
import {
  IProduct,
  IImage,
  IProductTranslation,
  IProductAudit,
  IAuditEntity,
  ILocationAvailabilityChanges,
  ILocationPricesChanges,
  ILocationTncsChanges,
} from 'types/productCatalogTypes';
import { ProductFormSchemaType } from 'components/shared/ProductForm/productFormConfig';
import { productCatalogFetch } from './productCatalogFetch';
import { getUserByUuid } from '../operatorService/userByUuidRequests';

export interface IGetProductsParams {
  buildingUuid?: string;
  saleItemGroup?: string;
  status?: string;
  filterByGroupTree?: string;
  catalog_uuid?: string;
}

export function getProducts(params: IGetProductsParams): Promise<IExtendedFetchResult<IProduct[]>> {
  const query = stringify(params);
  return productCatalogFetch<IProduct[]>(RequestMethods.GET, `/v2/products?${query}`);
}

const UNKNOWN = 'Unknown';

export async function getProduct(
  uuid: IProduct['uuid'],
  catalog_uuid: string,
): Promise<IExtendedFetchResult<IProduct>> {
  const query = stringify({ catalog_uuid });
  try {
    let productResponse = await productCatalogFetch<IProduct>(RequestMethods.GET, `/v2/products/${uuid}?${query}`);
    if (productResponse.data?.lastModifiedBy && isKnownUserUuid(productResponse.data?.lastModifiedBy)) {
      const { data: updator } = await getUserByUuid(productResponse.data?.lastModifiedBy);

      const lastModifiedByInfo = updator
        ? {
          name: updator.full_name || UNKNOWN,
          email: updator.email,
        }
        : null;

      productResponse = {
        ...productResponse,
        data: {
          ...productResponse.data,
          lastModifiedByInfo,
        },
      };
    }
    if (productResponse.data?.createdBy && isKnownUserUuid(productResponse.data?.createdBy)) {
      const { data: creator } = await getUserByUuid(productResponse.data?.createdBy);

      const createdByInfo = creator
        ? {
          name: creator.full_name || UNKNOWN,
          email: creator.email,
        }
        : null;

      productResponse = {
        ...productResponse,
        data: {
          ...productResponse.data,
          createdByInfo,
        },
      };
    }
    return productResponse;
  } catch (e) {
    return Promise.reject(e);
  }
}

export function getProductImages(uuid: IProduct['uuid'], catalogUuid: string): Promise<IExtendedFetchResult<IImage[]>> {
  const query = stringify({ cataog_uuid: catalogUuid });
  return productCatalogFetch<IImage[]>(RequestMethods.GET, `/v2/products/${uuid}/images?${query}`);
}

export function createProduct(
  product: IProduct | ProductFormSchemaType,
  catalog_uuid: string,
): Promise<IExtendedFetchResult<IProduct>> {
  const query = stringify({ catalog_uuid });
  return productCatalogFetch<IProduct>(RequestMethods.POST, `/v2/products/${product.uuid}?${query}`, product);
}

export function updateProduct(
  product: IProduct | ProductFormSchemaType,
  catalog_uuid: string,
): Promise<IExtendedFetchResult<IProduct>> {
  const query = stringify({ catalog_uuid });
  return productCatalogFetch<IProduct>(RequestMethods.PUT, `/v2/products/${product.uuid}?${query}`, product);
}

export function archiveProduct(uuid: string, catalog_uuid: string): Promise<IExtendedFetchResult<IProduct>> {
  const query = stringify({ catalog_uuid });
  return productCatalogFetch<IProduct>(RequestMethods.DELETE, `/v2/products/${uuid}?${query}`);
}

export function activateProduct(uuid: string, catalog_uuid: string): Promise<IExtendedFetchResult<IProduct>> {
  const query = stringify({ catalog_uuid });
  return productCatalogFetch<IProduct>(RequestMethods.PUT, `/v2/products/${uuid}/activate?${query}`);
}

export function getProductGroups(uuid: string, catalog_uuid: string): Promise<IExtendedFetchResult<string[]>> {
  const query = stringify({ catalog_uuid });
  return productCatalogFetch<string[]>(RequestMethods.GET, `/v2/products/${uuid}/groups?${query}`);
}

export function updateProductTranslation(
  translation: IProductTranslation,
  uuid: string,
): Promise<IExtendedFetchResult<null>> {
  return productCatalogFetch<null>(RequestMethods.PUT, `/v2/products/${uuid}/translation`, translation);
}

export function getProductAudit(
  uuid: string, pageSize = 5, offset = 0
): Promise<IExtendedFetchResult<IProductAudit[]>> {
  const quesry = stringify({ offset, pageSize });
  return productCatalogFetch<IProductAudit[]>(RequestMethods.GET, `/v2/audit/${uuid}/products?${quesry}`);
}

export function getLocationAudit(
  uuid: string, pageSize = 5, offset = 0
): Promise<IExtendedFetchResult<IAuditEntity<ILocationAvailabilityChanges>[]>> {
  const quesry = stringify({ offset, pageSize });
  return productCatalogFetch<IAuditEntity<ILocationAvailabilityChanges>[]>(
    RequestMethods.GET, `/v2/audit/${uuid}/available_buildings?${quesry}`
  );
}

export function getLocationPricesAudit(
  uuid: string, pageSize = 5, offset = 0
): Promise<IExtendedFetchResult<IAuditEntity<ILocationPricesChanges>[]>> {
  const quesry = stringify({ offset, pageSize });
  return productCatalogFetch<IAuditEntity<ILocationPricesChanges>[]>(
    RequestMethods.GET, `/v2/audit/${uuid}/location_prices?${quesry}`
  );
}

export function getLocationTncsAudit(
  uuid: string, pageSize = 5, offset = 0
  ): Promise<IExtendedFetchResult<IAuditEntity<ILocationTncsChanges>[]>> {
    const quesry = stringify({ offset, pageSize });
    return productCatalogFetch<IAuditEntity<ILocationTncsChanges>[]>(
    RequestMethods.GET, `/v2/audit/${uuid}/location_tncs?${quesry}`
  );
}