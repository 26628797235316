import { Button, Form, Modal } from '@wework/dieter-ui';
import { Form as FormikForm, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { FormInputType, FormValue } from 'components/shared/Form/FormConfig';
import { handleChange } from 'components/shared/Form/utils';
import Loading from 'components/shared/Loading';
import { ILocationAttributesRecord, ILocationAttributesFormValues } from 'types/productCatalogTypes';
import { LocationAttributesFormSchema } from './locationAttributesFormConfig';
import { LocationAttributesFormCheckBoxInput } from './LocationAttributesFormInputs';

interface ILocationAttributesFormProps {
  locationAttributes: ILocationAttributesRecord;
  disabled?: boolean;
  onSave: (attributes: ILocationAttributesRecord) => void;
}

export const LocationAttributesForm = ({
  locationAttributes,
  disabled,
  onSave,
}: ILocationAttributesFormProps): JSX.Element => {
  const [formAttributes, setFormAttributes] = useState<ILocationAttributesFormValues | null>(null);
  const ecommerceEnabled = locationAttributes?.attributes?.ECOMMERCE_ENABLED || false;
  useEffect(() => {
    const formattedAttributes = {
      ...locationAttributes,
      ecommerceEnabled,
    };
    setFormAttributes(formattedAttributes);
  }, [locationAttributes]);

  const handleSubmit = (values: ILocationAttributesFormValues): void => {
    const castAttributes = LocationAttributesFormSchema.cast(values, {
      stripUnknown: true,
    }) as ILocationAttributesRecord;
    castAttributes.attributes = { ECOMMERCE_ENABLED: values.ecommerceEnabled };
    onSave(castAttributes);
  };
  if (!formAttributes) {
    return <Loading />;
  }
  return (
    <>
      <Formik initialValues={formAttributes} validationSchema={LocationAttributesFormSchema} onSubmit={handleSubmit}>
        {({ handleSubmit: formikHandleSubmit, setFieldValue, setFieldTouched, values, dirty }): JSX.Element => (
          <>
            <Modal.Content className="location-attributes-modal--modal-content">
              <Form as={FormikForm} onSubmit={formikHandleSubmit} className="location-attributes-modal--form">
                <LocationAttributesFormCheckBoxInput
                  handleOnChange={(name: string, value: boolean): void =>
                    handleChange<FormValue>(name, value, setFieldValue, setFieldTouched)
                  }
                  labelText="wework.com enabled?"
                  name="ecommerceEnabled"
                  inputType={FormInputType.CHECKBOX}
                  disabled={disabled}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <>
                <Button primary disabled={disabled || !dirty} onClick={(): void => handleSubmit(values)}>
                  Set Attributes
                </Button>
              </>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </>
  );
};
