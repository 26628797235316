import React, { forwardRef, useEffect, MutableRefObject } from 'react';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';

import { AuditActionType, IProductAudit, ILocationAvailabilityChanges } from 'types/productCatalogTypes';
import Loading from 'components/shared/Loading/Loading';

import './styles.scss';

export const TableContainer = ({ children }: { children: React.ReactElement[] }) => (
  <div className="tableContainer">{children}</div>
);

export const TableHeader = ({ children }: { children: React.ReactElement[] }) => (
  <div className="tableHeader">{children}</div>
);

export const TableHeaderCell = ({ children, size }: { children: React.ReactElement | string; size: string }) => (
  <div className={cn('tableHeaderCell', `size-${size}`)}>{children}</div>
);

export const TableRow = ({ children }: { children: React.ReactNode | React.ReactNode[] }): React.ReactElement => (
  <div className="tableRow">{children}</div>
);

export const TableCell = ({ children, size }: { children: React.ReactElement | AuditActionType; size: string }) => (
  <div className={cn('tableCell', `size-${size}`)}>{children}</div>
);

export const TableBody = forwardRef(function TableBodyRenderer({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return <div className="tableBody">{children}</div>;
});

export type AuditColumn = {
  title: string;
  key: string;
  size: string;
};

export const columns = [
  {
    title: 'Date',
    key: 'date',
    size: 'md',
  },
  {
    title: 'User',
    key: 'user',
    size: 'lg',
  },
  {
    title: 'Action',
    key: 'action',
    size: 'md',
  },
  {
    title: 'ATTRIBUTES / Changes',
    key: 'attributes',
    size: 'auto',
  },
];

export const LoadMore = ({
  setOffset,
  stop,
  root,
}: {
  stop: boolean;
  setOffset: () => void;
  root: MutableRefObject<null>;
}) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
    root: root.current,
  });

  useEffect(() => {
    if (inView && !stop) {
      setOffset();
    }
  }, [inView, stop]);

  return !stop ? (
    <div ref={ref} className="auditLoadMore">
      <Loading />
    </div>
  ) : null;
};

export const AuditRecordPlaceholder = () => (
  <div className="auditRecordPlaceholder">
    <Loading />
  </div>
);

export const ESTIMATED_ITEM_HEIGHT = 200;
