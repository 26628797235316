import React, { useMemo, useState } from 'react';
import { Menu } from '@wework/dieter-ui';

import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { last, map } from 'lodash';
import { AuditActionType } from 'types/productCatalogTypes';
import AuditTrailInfo from 'components/shared/ProductForm/AuditTrailInfo';
import { IEditProductTabCommonProps } from '../editProductTabsConfig';
import { AuditProductTab } from './AuditProductTab';
import { AuditLocationAvailabilityTab } from './AuditLocationAvailabilityTab';
import { AuditLocationPricesTab } from './AuditLocationPricesTab';
import { AuditLocationTncsTab } from './AuditLocationTncsTab';

const EditAuditTab = (props: IEditProductTabCommonProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabsConfig = useMemo(
    () => [
      {
        name: 'Product',
        path: 'product-history',
        component: <AuditProductTab {...props} />,
      },
      {
        name: 'Location',
        path: 'location-history',
        component: <AuditLocationAvailabilityTab {...props} />,
      },
      {
        name: 'Pricing',
        path: 'location-prices-history',
        component: <AuditLocationPricesTab {...props} />,
      },
      {
        name: 'TnC',
        path: 'location-tncs',
        component: <AuditLocationTncsTab {...props} />,
      },
    ],
    [props],
  );

  const [activeTab, setActiveTab] = useState(() => {
    const currentPath = last(location.pathname.split('/'));
    return currentPath && map(tabsConfig, 'path').includes(currentPath) ? currentPath : tabsConfig[0].path;
  });

  const { product } = props;

  const onMenuTabClick = (path: string) => {
    setActiveTab(path);
    navigate(path);
  };

  return (
    <>
      {product?.createdAt ? (
        <AuditTrailInfo action={AuditActionType.CREATED} date={product?.createdAt} userInfo={product?.createdByInfo} />
      ) : null}
      {product?.updatedAt ? (
        <AuditTrailInfo
          action={AuditActionType.UPDATED}
          date={product?.updatedAt}
          userInfo={product?.lastModifiedByInfo}
        />
      ) : null}
      <Menu pointing secondary>
        {tabsConfig.map(({ name, path }) => {
          return <Menu.Item key={path} name={name} active={path === activeTab} onClick={() => onMenuTabClick(path)} />;
        })}
      </Menu>
      <Routes>
        {tabsConfig.map((config) => (
          <Route key={config.path} path={config.path} element={config.component} />
        ))}
        <Route path="*" element={<Navigate to={tabsConfig[0].path} replace />} />
      </Routes>
    </>
  );
};

export default EditAuditTab;
