import { Input, Message } from '@wework/dieter-ui';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { concat, difference, filter, forEach, isEqual, size, uniq, xor } from 'lodash';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { useCopyEffect } from 'hooks/useCopyEffect';
import {
  deleteAvailableBuildings,
  updateAvailableBuildings,
} from 'networking/productCatalog/availableBuildingsRequests';
import { bulkUpdateLocationPrices } from 'networking/productCatalog/locationPriceRequests';
import { bulkUpdateLocationAttributes } from 'networking/productCatalog/locationAttributesRequests';
import {
  ILocationPrice,
  IProduct,
  LocationPriceLocationType,
  ILocationAttributesRecord,
  IGeogroupingObject,
  IBuildingObject,
  ILocationObject,
  LocationAttributesLocationType,
} from 'types/productCatalogTypes';
import { locationPriceDefaultValues } from 'components/shared/LocationPriceForm/locationPriceFormConfig';
import { locationAttributesDefaultValues } from 'components/shared/LocationAttributesForm/locationAttributesFormConfig';

import { LocationPriceModal } from 'components/shared/LocationPriceForm/LocationPriceModal';
import { WeCheckboxTree } from 'components/shared/WeCheckboxTree/WeCheckboxTree';
import './availabilityForm.scss';
import { assembleLocationsMap, ILocationsMap } from 'types/mappers';
import { IExtendedFetchResult } from 'networking/fetchConfig';
import {
  assembleNodes,
  bulkUpdateLocationPricesPayload,
  filterNodes,
  ILocationPriceMap,
  locationPricesArrayToMap,
  locationPricesMapToArray,
  ILocationAttributesRecordMap,
  locationAttributesArrayToMap,
  locationAttributesMapToArray,
  bulkUpdateLocationAttributesPayload,
  ICheckboxTreeNode,
} from './availabilityFormUtils';
import { FormActionDrawer } from '../FormActionDrawer/FormActionDrawer';
import { AvailabilityConfirmationModal } from './AvailabilityConfirmationModal';

interface IAvailabilityFormProps {
  product: IProduct;
  availableBuildingIds: string[];
  locationPrices: ILocationPrice[];
  locationAttributes: ILocationAttributesRecord[];
  geogroupings: IGeogroupingObject[];
  buildings: IBuildingObject[];
  isPricingEditable?: boolean;
  isAvailabilityEditable?: boolean;
  onBuildingsSave: (ids: string[]) => void;
  onLocationPricesSave: (prices: ILocationPrice[]) => void;
  onLocationAttributesSave: (attributes: ILocationAttributesRecord[]) => void;
}

export const AvailabilityForm = ({
  product,
  availableBuildingIds = [],
  locationPrices = [],
  locationAttributes = [],
  geogroupings,
  buildings,
  isPricingEditable,
  isAvailabilityEditable,
  onBuildingsSave,
  onLocationPricesSave,
  onLocationAttributesSave,
}: IAvailabilityFormProps): JSX.Element => {
  const isFormEditable = isPricingEditable || isAvailabilityEditable;
  // Objects to look up locations and prices by location ID
  const [locationsMap, setLocationsMap] = useState<ILocationsMap>(assembleLocationsMap(geogroupings, buildings));
  const [pricesMap, setPricesMap] = useState<ILocationPriceMap>(locationPricesArrayToMap(locationPrices));
  const [locationAttributesMap, setLocationAttributesMap] = useState<ILocationAttributesRecordMap>(
    locationAttributesArrayToMap(locationAttributes),
  );

  // Checkbox tree nodes
  const [nodes, setNodes] = useState<ICheckboxTreeNode[]>([]);
  const [filteredNodes, setFilteredNodes] = useState<ICheckboxTreeNode[]>([]);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [filterQuery, setFilterQuery] = useState<string>('');

  // Form state
  const [formAvailableBuildingIds, setFormAvailableBuildingIds] = useState<string[]>([]);
  const [formPrices, setFormPrices] = useState<ILocationPriceMap>({});
  const [formAttributesValues, setFormLocationAttributesValues] = useState<ILocationAttributesRecordMap>({});

  const [submitting, setSubmitting] = useState<boolean>(false);

  // Modal state
  const [showPriceModal, setShowPriceModal] = useState<boolean>(false);
  const [activeModalLocationPrice, setActiveModalLocationPrice] = useState<ILocationPrice | null>(null);
  const [activeModalLocationAttributes, setActiveModalLocationAttributes] = useState<ILocationAttributesRecord | null>(
    null,
  );
  const [activeModalLocation, setActiveModalLocation] = useState<ILocationObject | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const { flashError, flashSuccess } = useFlashMessageContext();

  const addedBuildingIds = difference(formAvailableBuildingIds, availableBuildingIds);
  const removedBuildingIds = difference(availableBuildingIds, formAvailableBuildingIds);
  const isAvailabilityTouched = !isEmpty(addedBuildingIds) || !isEmpty(removedBuildingIds);

  const isPricesTouched = !isEqual(pricesMap, formPrices);
  const isAttributesTouched = useMemo(() => {
    const newLocations: ILocationAttributesRecordMap = {};
    const diff: ILocationAttributesRecordMap = {};
    forEach(formAttributesValues, (location, key: string) => {
      if (!locationAttributesMap[key]) {
        newLocations[key] = location;
      } else if (location.attributes.ECOMMERCE_ENABLED !== locationAttributesMap[key].attributes.ECOMMERCE_ENABLED) {
        diff[key] = location;
      }
    });
    return size(diff) > 0 || !isEmpty(filter(newLocations, (location) => location.attributes.ECOMMERCE_ENABLED));
  }, [formAttributesValues, locationAttributesMap]);

  // Assemble locations in object for lookup by location ID
  useEffect(() => {
    const locations = assembleLocationsMap(geogroupings, buildings);
    setLocationsMap(locations);
  }, [geogroupings.length, buildings.length]);

  useCopyEffect(availableBuildingIds, setFormAvailableBuildingIds);

  // Assemble prices in object for lookup by location ID
  // and hydrate form with existing prices
  useCopyEffect(locationPrices, setPricesMap, locationPricesArrayToMap);
  useCopyEffect(locationPrices, setFormPrices, locationPricesArrayToMap);

  useCopyEffect(locationAttributes, setLocationAttributesMap, locationAttributesArrayToMap);
  useCopyEffect(locationAttributes, setFormLocationAttributesValues, locationAttributesArrayToMap);

  // Assemble all tree nodes
  useEffect(() => {
    const allNodes = assembleNodes(locationsMap, formPrices, formAttributesValues, true);
    setNodes(allNodes);
  }, [locationsMap, formPrices, formAttributesValues]);

  // Set filtered nodes
  useEffect(() => {
    const newFilteredNodes = filterNodes(nodes, filterQuery);
    setFilteredNodes(newFilteredNodes);
  }, [nodes, filterQuery]);

  function addBuildingsRequest(): Promise<IExtendedFetchResult<null> | null> {
    return isEmpty(addedBuildingIds) ? Promise.resolve(null) : updateAvailableBuildings(product.uuid, addedBuildingIds);
  }

  function removeBuildingsRequest(): Promise<IExtendedFetchResult<null> | null> {
    return isEmpty(removedBuildingIds)
      ? Promise.resolve(null)
      : deleteAvailableBuildings(product.uuid, removedBuildingIds);
  }

  function updatePricesRequest(): Promise<IExtendedFetchResult<ILocationPrice[]> | null> {
    const pricesPayload = bulkUpdateLocationPricesPayload(pricesMap, formPrices);
    const { createList, updateList, deleteList } = pricesPayload;
    if (isEmpty(createList) && isEmpty(updateList) && isEmpty(deleteList)) {
      return Promise.resolve(null);
    }
    return bulkUpdateLocationPrices(product, pricesPayload);
  }

  function updateLocationAttributesRequest(): Promise<IExtendedFetchResult<ILocationAttributesRecord[]> | null> {
    const attributesPayload = bulkUpdateLocationAttributesPayload(locationAttributesMap, formAttributesValues);
    const { createList, updateList } = attributesPayload;
    if (isEmpty(createList) && isEmpty(updateList)) {
      return Promise.resolve(null);
    }
    return bulkUpdateLocationAttributes(product, attributesPayload);
  }

  const handleSubmit = async (): Promise<void> => {
    setSubmitting(true);
    const [addBuildingsResult, removeBuildingsResult, pricesResult, locationAttributesResult] = await Promise.all([
      addBuildingsRequest(),
      removeBuildingsRequest(),
      updatePricesRequest(),
      updateLocationAttributesRequest(),
    ]);

    let newAvailableBuildingIds: string[] = [...availableBuildingIds];
    if (addBuildingsResult) {
      if (addBuildingsResult.error) {
        flashError(addBuildingsResult.errorData ? addBuildingsResult.errorData.message : 'An error has occurred');
      } else {
        flashSuccess('Buildings enabled successfully');
        newAvailableBuildingIds = uniq(concat(newAvailableBuildingIds, addedBuildingIds));
      }
    }

    if (removeBuildingsResult) {
      if (removeBuildingsResult.error) {
        flashError(removeBuildingsResult.errorData ? removeBuildingsResult.errorData.message : 'An error has occurred');
      } else {
        flashSuccess('Buildings disabled successfully');
        newAvailableBuildingIds = xor(newAvailableBuildingIds, removedBuildingIds);
      }
    }
    // update initial building availability data if it was changed
    if (!isEqual(availableBuildingIds, newAvailableBuildingIds)) {
      onBuildingsSave(newAvailableBuildingIds);
    }

    if (pricesResult) {
      if (pricesResult.error) {
        flashError(pricesResult.errorData ? pricesResult.errorData.message : 'An error has occurred');
      } else {
        flashSuccess('Prices saved');
        const { data } = pricesResult;
        if (data) {
          // Save IDs of newly created prices
          const createdPrices = locationPricesArrayToMap(data);
          const newPricesMap = {
            ...formPrices,
            ...createdPrices,
          };
          const newPrices = locationPricesMapToArray(newPricesMap);
          onLocationPricesSave(newPrices);
        }
      }
    }
    if (locationAttributesResult) {
      if (locationAttributesResult.error) {
        flashError(
          locationAttributesResult.errorData ? locationAttributesResult.errorData.message : 'An error has occurred',
        );
      } else {
        flashSuccess('Attributes saved');
        const { data } = locationAttributesResult;
        if (data) {
          const createdAttributes = locationAttributesArrayToMap(data);
          const newAttributesMap = {
            ...formAttributesValues,
            ...createdAttributes,
          };
          const newAttributes = locationAttributesMapToArray(newAttributesMap);
          onLocationAttributesSave(newAttributes);
        }
      }
    }
    setSubmitting(false);
  };

  const showFilterError = (): boolean => filteredNodes.length === 0 && filterQuery.length > 0;

  const getLocation = (id: string): ILocationObject => locationsMap.geogroupings[id] || locationsMap.buildings[id];

  const getLocationPrice = (locationUuid: string): ILocationPrice => {
    return (
      formPrices[locationUuid] || {
        ...locationPriceDefaultValues,
        locationUuid,
        locationType: locationsMap.geogroupings[locationUuid]
          ? LocationPriceLocationType.GEOGROUPING
          : LocationPriceLocationType.BUILDING,
      }
    );
  };

  const getLocationAttributes = (locationUuid: string): ILocationAttributesRecord => {
    return (
      formAttributesValues[locationUuid] || {
        ...locationAttributesDefaultValues,
        locationUuid,
        locationType: locationsMap.geogroupings[locationUuid]
          ? LocationAttributesLocationType.GEOGROUPING
          : LocationAttributesLocationType.BUILDING,
      }
    );
  };

  const openPriceModal = (locationUuid: string): void => {
    const location = getLocation(locationUuid);
    const locationPrice = getLocationPrice(locationUuid);
    const locationAttributes = getLocationAttributes(locationUuid);
    setActiveModalLocation(location);
    setActiveModalLocationPrice(locationPrice);
    setActiveModalLocationAttributes(locationAttributes);

    setShowPriceModal(true);
  };

  const closePriceModal = (): void => {
    setShowPriceModal(false);
  };

  const handlePriceSave = (price: ILocationPrice): void => {
    setFormPrices({
      ...formPrices,
      [price.locationUuid]: price,
    });
    closePriceModal();
  };

  const handleAttributesSave = (attributes: ILocationAttributesRecord): void => {
    setFormLocationAttributesValues({
      ...formAttributesValues,
      [attributes.locationUuid]: attributes,
    });
    closePriceModal();
  };

  const handlePriceDelete = (price: ILocationPrice): void => {
    const newPrices = { ...formPrices };
    delete newPrices[price.locationUuid];
    setFormPrices(newPrices);
    closePriceModal();
  };

  const openConfirmationModal = () => setShowConfirmationModal(true);
  const closeConfirmationModal = () => setShowConfirmationModal(false);
  const handleSave = () => {
    closeConfirmationModal();
    handleSubmit();
  };

  return (
    <>
      <Input
        value={filterQuery}
        onChange={(event): void => setFilterQuery(event.target.value)}
        placeholder="Type to filter..."
      />
      <div className="availability-form--tree-container">
        <WeCheckboxTree
          nodes={filteredNodes}
          checked={formAvailableBuildingIds}
          expanded={expandedIds}
          onCheck={setFormAvailableBuildingIds}
          onExpand={setExpandedIds}
          showExpandAll
          disabled={!isAvailabilityEditable}
          onClick={isFormEditable ? ({ value }): void => openPriceModal(value) : undefined}
        />
      </div>
      {showFilterError() && <Message error>No locations match &quot;{filterQuery}&quot;</Message>}

      {isFormEditable && (
        <FormActionDrawer
          actions={[
            {
              'data-test-id': 'submit',
              key: 'submit',
              primary: true,
              loading: submitting,
              disabled: submitting || (!isAvailabilityTouched && !isAttributesTouched && !isPricesTouched),
              content: 'Submit',
              onClick: openConfirmationModal,
            },
          ]}
        />
      )}

      {showPriceModal && (
        <LocationPriceModal
          locationName={activeModalLocation?.name || activeModalLocation?.default_name || ''}
          locationPrice={activeModalLocationPrice}
          locationAttributes={activeModalLocationAttributes}
          isPricingEditable={isPricingEditable}
          productUuid={product.uuid}
          onClose={closePriceModal}
          onSaveLocationPrice={handlePriceSave}
          onSaveLocationAttributes={handleAttributesSave}
          onDelete={handlePriceDelete}
        />
      )}
      {showConfirmationModal && (
        <AvailabilityConfirmationModal
          geogroupings={geogroupings}
          locationAttributes={locationAttributesMap}
          changedLocationAttributes={formAttributesValues}
          prices={pricesMap}
          changedPrices={formPrices}
          addedBuildingIds={addedBuildingIds}
          removedBuildingIds={removedBuildingIds}
          buildings={buildings}
          onClose={closeConfirmationModal}
          onSave={handleSave}
        />
      )}
    </>
  );
};
