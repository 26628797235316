import { Checkbox } from '@wework/dieter-ui';
import { CheckboxProps } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import React from 'react';

import { fieldError } from 'components/shared/Form/utils';

export interface LocationAttributesFormCheckBoxFieldConfig {
  labelText: string;
  name: string;
  placeholder?: string;
  handleOnChange: (name: string, checked: boolean) => void;
  disabled?: boolean;
  readOnly?: boolean;
  inputType: string;
}

const LocationAttributesFormCheckBoxInput = ({
  labelText,
  name,
  handleOnChange,
  disabled,
  readOnly,
}: LocationAttributesFormCheckBoxFieldConfig): JSX.Element => (
  <Field name={name} id={name}>
    {({ form, field }: FieldProps): JSX.Element => (
      <Checkbox
        className="attributes-form__field"
        error={fieldError(form.touched, form.errors, name)}
        name={name}
        id={name}
        label={labelText}
        onChange={(_, { checked = false }: CheckboxProps): void => handleOnChange(name, checked)}
        checked={field.value}
        disabled={disabled}
        readOnly={readOnly}
      />
    )}
  </Field>
);

export { LocationAttributesFormCheckBoxInput };
