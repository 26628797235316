import * as Yup from 'yup';
import { LocationAttributesLocationType } from 'types/productCatalogTypes';

export enum LocationAttributesFormField {
  LOCATION_UUID = 'locationUuid',
  LOCATION_TYPE = 'locationType',
  ECOMMERCE_ENABLED = 'ECOMMERCE_ENABLED',
}

export const LocationAttributesFormSchema = Yup.object().shape({
  id: Yup.number(),
  locationUuid: Yup.string().required(),
  locationType: Yup.string(),
  attributes: Yup.object()
    .shape({
      ECOMMERCE_ENABLED: Yup.boolean().required(),
    })
    .required(),
});

export const locationAttributesDefaultValues = {
  [LocationAttributesFormField.LOCATION_UUID]: '',
  [LocationAttributesFormField.LOCATION_TYPE]: LocationAttributesLocationType.GEOGROUPING,
  attributes: {
    [LocationAttributesFormField.ECOMMERCE_ENABLED]: false,
  },
};
